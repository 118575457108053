export const basicColors = {
  white: '#ffffff',
  black: '#000000',
  red: '#f04124',
  yellow: '#f0b900',
  green: '#43AC6A',
  lightblue: '#d9edf7',
  darkblue: '#3A7FC2',
  transparent: 'hsla(0, 0%, 100%, 0)',
};

export const themeColors = {
  main: '#d9003f',
  secondary: '#3c5da1',
};

export const paletteBlueGrey = {
  25: '#F4F5FA',
  50: '#ECEFF7',
  75: '#E1E4ED',
  100: '#D5DAE3',
  200: '#c0c8de',
  300: '#B8C0D6',
  400: '#99a1b7',
  500: '#6f7c92',
  600: '#59627a',
  700: '#333A45',
  800: '#1B2A49',
  900: '#1A1D23',
};
