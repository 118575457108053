import React from 'react';
import { IconSvg } from 'reactackle-icons';

export const IconGithub = props => (
  <IconSvg {...props} size={'normal'}>
    <svg viewBox="0 0 40 40">
      <path
        d="M20,0C10.06,0,2,8.06,2,18c0,7.95,5.16,14.7,12.31,17.08c0.9,0.17,1.23-0.39,1.23-0.87
	c0-0.43-0.02-1.56-0.02-3.06c-5.01,1.09-6.06-2.41-6.06-2.41c-0.82-2.08-2-2.63-2-2.63c-1.63-1.12,0.12-1.09,0.12-1.09
	c1.81,0.13,2.76,1.85,2.76,1.85c1.61,2.75,4.21,1.96,5.24,1.5c0.16-1.16,0.63-1.96,1.14-2.41c-4-0.45-8.2-2-8.2-8.9
	c0-1.97,0.7-3.57,1.85-4.83c-0.19-0.46-0.8-2.29,0.18-4.76c0,0,1.51-0.48,4.95,1.85c1.44-0.4,2.98-0.6,4.51-0.61
	c1.53,0.01,3.07,0.21,4.51,0.61c3.44-2.33,4.94-1.85,4.94-1.85c0.98,2.48,0.36,4.31,0.18,4.76c1.15,1.26,1.85,2.87,1.85,4.83
	c0,6.91-4.21,8.44-8.22,8.88c0.65,0.56,1.22,1.65,1.22,3.33c0,2.41-0.02,4.35-0.02,4.94c0,0.48,0.32,1.04,1.24,0.87
	C32.85,32.69,38,25.95,38,18C38,8.06,29.94,0,20,0z"
      />
    </svg>
  </IconSvg>
);
